import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from '@app/core/core.module';
import { GraphQLModule } from './graphql.module';

@NgModule({
	declarations: [
		AppComponent
	],
	bootstrap: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		CoreModule,
		GraphQLModule
	],
	providers: [
		provideHttpClient(withInterceptorsFromDi())
	]
})
export class AppModule { }
