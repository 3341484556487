import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ApolloLink, ServerError } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Router } from '@angular/router';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import { environment } from '@environments/environment';
import { SessionService } from '@app/core/services/session/session.service';
import {
  projectPendingChanges,
  projectPendingChangesKeys,
  surveyQuestionsPendingChanges
} from '@app/core/services/project-changes/project-changes.service';
import { UtilityService } from '@app/shared/services/utility/utility.service';

const uri = environment.graphqlUri;
export function createApollo(
  sessionService: SessionService,
  router: Router,
  util: UtilityService
): ApolloClientOptions<any> {
  // define the auth interceptor for the apollo httpLink
  const authInterceptor = setContext((operation, context) => {
    // retrieve the current session
    const session = sessionService.getSession();
    // if a session exists, set the session
    // token in the authorization header
    if (!session) {
      sessionService.destroySession();
      return {};
    }
    else {
      return {
        headers: {
          Authorization: session.token
        }
      };
    }
  });

  // define the error handler
  const errorHandler = onError(({ graphQLErrors, networkError }) => {
    // if the error is a graphql error
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        // log the error
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        // if the error is related to authentication, navigate to the signin page
        if (extensions?.code === 'UNAUTHENTICATED_ERROR') {
          sessionService.destroySession();
          router.navigateByUrl('/signin');
        }
        else {
          // otherwise navigate to the error page
          router.navigate(['/error'], { queryParams: { path: encodeURIComponent(window.location.pathname) }});
        }
      });
    }
    // if the error is a network error
    if (networkError) {
      // log the error
      console.log(`[Network error]: ${JSON.stringify(networkError)}`);
      // if the error is 401 unauthorized, navigate to the signin page
      if ((networkError as ServerError).statusCode === 401) {
        sessionService.destroySession();
        router.navigateByUrl('/signin');
      }
      // else navigate to the error page
      else {
        router.navigate(['/error'], { queryParams: { path: encodeURIComponent(window.location.pathname) }});
      }
    }
  });

  return {
    link: ApolloLink.from([
      authInterceptor,
      errorHandler,
      createUploadLink({
        uri
      }) as any
    ]),
    cache: createCache(util)
  };
}

@NgModule({
  imports: [
    ApolloModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [SessionService, Router, UtilityService],
    },
  ],
})
export class GraphQLModule {}

// create and return a new InMemoryCache
export function createCache(util: UtilityService): InMemoryCache {
  return new InMemoryCache({
    typePolicies: {
      // query type
      Query: {
        fields: {
          // define the policy for merging userInsightProjects
          userInsightProjects: {
            merge(existing, incoming) {
              // overwrite any existing projects with the
              // incoming new projects
              return incoming;
            }
          },
          // define the policy for merging userProjects
          listUserProjects: {
            merge(existing, incoming) {
              // overwrite any existing projects with the
              // incoming new projects
              return incoming;
            }
          },
          // define the policy for merging projectInvestmentOpportunityResults
          projectInvestmentOpportunityResults: {
            merge(existing, incoming) {
              // overwrite any existing results with the
              // incoming new results
              return incoming;
            }
          },
          // define the policy for merging resultsTable
          resultsTable: {
            merge(existing, incoming) {
              // overwrite any existing results with the
              // incoming new results
              return incoming;
            }
          }
        }
      },
      // project type
      Project: {
        fields: {
          // define the policy for reading the project pending
          // changes reactive variable
          pendingChanges: {
            read() {
              return projectPendingChanges();
            }
          },
          // define the policy for reading the project pending
          // changes keys reactive variable
          pendingChangesKeys: {
            read() {
              return projectPendingChangesKeys();
            }
          },
          // define the policy for reading the survey question
          // pending changes reactive variable
          surveyQuestionsPendingChanges: {
            read() {
              return surveyQuestionsPendingChanges();
            }
          },
          // define policies for the landArea field
          landArea: {
            // when reading the land area value, convert it from m2 to ft2
            read(value) {
              return util.m2ToFt2(value);
            }
          },
          // define policies for the roofArea field
          roofArea: {
            // when reading the roof area value, convert it from m2 to ft2
            read(value) {
              return util.m2ToFt2(value);
            }
          }
        }
      },
      // SurveyQuestion type
      SurveyQuestion: {
        fields: {
          // define the policy for merging surveyAnswers
          surveyAnswers: {
            merge(existing, incoming) {
              // overwrite any existing survey answers with the
              // incoming new survey answers
              return incoming;
            }
          }
        }
      }
    }
  });
}
